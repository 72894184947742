import {
  BANNER_HTML_ELEMENT_ID,
  OVERLAY_HTML_ELEMENT_ID
} from 'constants/alertHTMLIds';
import { FUJI } from 'constants/dinerSignupConstants';

export const OVERLAY_ROOT_INIT_FUNCTION_MAP = {
  SENSEI_OSAKA: () => {},
  PROMOTION: () => {},
  ANNOUNCEMENT: () => {},
  CUSTOM: legacy_template => {
    if (legacy_template && legacy_template.toLowerCase() === FUJI) {
      const renderedElement = document.getElementById(OVERLAY_HTML_ELEMENT_ID);
      if (!renderedElement) {
        return;
      }

      renderedElement.className = 'modal modal--alert';
      renderedElement.dataset.modal = 'auto';
      renderedElement.setAttribute('role', 'region');
    }
  },
  FUJI: () => {
    const renderedElement = document.getElementById(OVERLAY_HTML_ELEMENT_ID);
    if (!renderedElement) {
      return;
    }

    renderedElement.className = 'modal modal--alert';
    renderedElement.dataset.modal = 'auto';
    renderedElement.setAttribute('role', 'region');
  }
};

const setAlertClass = () => {
  const renderedElement = document.getElementById(BANNER_HTML_ELEMENT_ID);
  if (!renderedElement) {
    return;
  }

  renderedElement.addEventListener('transitionend', event => {
    // Since this event can be fired by children (bubbling), we need to filter by the target of the event
    if (event.target !== renderedElement) {
      return;
    }
    // calling window resize event to fix an overlay issue with the nav bar
    window.dispatchEvent(new Event('resize'));
  });

  renderedElement.classList.add('alert');
  renderedElement.classList.add('banner-open');
};

export const BANNER_ROOT_INIT_FUNCTION_MAP = {
  SENSEI_OSAKA: setAlertClass,
  FUJI: setAlertClass
};
