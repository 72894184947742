import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormControl, MenuItem, FormHelperText, Stack } from '@mui/material';
import StyledSelect from 'components/StyledSelect';

const MONTHS = {
  1: { value: 1, label: 'January', days: 31 },
  2: { value: 2, label: 'February', days: 29 },
  3: { value: 3, label: 'March', days: 31 },
  4: { value: 4, label: 'April', days: 30 },
  5: { value: 5, label: 'May', days: 31 },
  6: { value: 6, label: 'June', days: 30 },
  7: { value: 7, label: 'July', days: 31 },
  8: { value: 8, label: 'August', days: 31 },
  9: { value: 9, label: 'September', days: 30 },
  10: { value: 10, label: 'October', days: 31 },
  11: { value: 11, label: 'November', days: 30 },
  12: { value: 12, label: 'December', days: 31 }
};

// TODO https://getbentobox.atlassian.net/browse/DMD-3007
// see if NativeSelect works better on mobile

/**
 * @param {{
 *  onChange: (values: { month: string, day: string }) => void
 * }} props
 * */
const BirthdayField = ({ onChange, error }) => {
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [year, setYear] = useState('');

  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(100), (val, index) => currentYear - index);

  useEffect(() => {
    if (month && day && year) {
      onChange({ month, day, year });
    }
  }, [month, day, year]);

  return (
    <Stack direction="row" spacing={1} marginBottom="1rem">
      <FormControl fullWidth error={error} padding="0">
        <StyledSelect
          dataTestId="birthday-month"
          value={month}
          label={!month ? 'Month' : ''}
          onChange={e => {
            setMonth(e.target.value);
            setDay('');
          }}
        >
          {Object.values(MONTHS).map(m => (
            <MenuItem key={m.value} value={m.value}>
              {m.label}
            </MenuItem>
          ))}
        </StyledSelect>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
      <FormControl fullWidth>
        <StyledSelect
          dataTestId="birthday-day"
          label={!day ? 'Day' : ''}
          value={day}
          disabled={!month}
          onChange={e => setDay(e.target.value)}
        >
          {Array.from(
            { length: MONTHS[month]?.days || 0 },
            (_, i) => i + 1
          ).map(el => (
            <MenuItem key={el} value={el}>
              {el.toString().padStart(2, '0')}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
      <FormControl fullWidth padding="0">
        <StyledSelect
          dataTestId="birthday-year"
          value={year}
          label={!year ? 'Year' : ''}
          onChange={e => {
            setYear(e.target.value);
          }}
        >
          {Object.values(years).map(y => (
            <MenuItem key={y} value={y}>
              {y}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </Stack>
  );
};

BirthdayField.propTypes = {
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired
};

export default BirthdayField;
