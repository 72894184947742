import { eventCategories } from 'constants/tracking';

const trackEventWithBentoAnalytics = (actionType, label, value) => {
  if (!window.BentoAnalytics) {
    return;
  }

  window.BentoAnalytics.trackEvent(
    eventCategories.CUSTOMER_WEBSITES,
    actionType,
    label,
    value
  );
};

export default trackEventWithBentoAnalytics;
