export const environmentNames = {
  PRODUCTION: 'production',
  STAGING: 'staging',
  DEVELOPMENT: 'development',
  UNKNOWN: 'unknown'
};

export const SENTRY_DSN =
  'https://1e8b07a4cd8f4d18b56d00c5d224badc@o11591.ingest.sentry.io/6343811';

export const DATADOG_SITE = 'datadoghq.com';
export const DATADOG_SERVICE = 'alerts-component';
