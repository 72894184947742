export const eventCategories = {
  CUSTOMER_WEBSITES: 'Customer Websites'
};

export const actionTypes = {
  CLICK: 'Click',
  SHOW: 'Show'
};

export const eventLabels = {
  ALERT_SHOWN: 'Alert Shown',
  ALERT_CLOSE_BUTTON: 'Alert Close Button',
  ALERT_SUBMIT_BUTTON: 'Alert Submit Button'
};

export const eventValues = {
  ALERT_TEMPLATE: 'Alert Template'
};

export const CLOSE_OVERLAY_EVENT_NAME = 'bentobox_close_overlay_event';
