import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@mui/material';

import AlertButton from 'components/AlertButton';
import AlertForm from 'components/AlertForm';
import {
  NO_THANKS,
  FORM_STATES,
  PROMOTION,
  ANNOUNCEMENT
} from 'constants/dinerSignupConstants';
import { Close } from '@mui/icons-material';
import { focalPointFloatsToString } from 'utils/dinerSignup';

const ContentSection = ({
  context,
  template,
  alert_form_context,
  handleEmailChange,
  handleBirthdayChange,
  handlePhoneNumberChange,
  onClick,
  onClose,
  errors,
  formState,
  isLoading,
  customButtonLabel
}) => {
  // PROMOTION template: display alert form and close button except in success step
  // ANNOUNCEMENT template: display alert form and close button except in success step
  // ... only when optional_alert_form_fields_enabled is set to true
  const shouldDisplayAlertForm =
    (template === PROMOTION && formState !== FORM_STATES.SUCCESS) ||
    (template === ANNOUNCEMENT &&
      formState !== FORM_STATES.SUCCESS &&
      alert_form_context?.optional_alert_form_fields_enabled);

  return (
    <Box
      sx={theme => ({
        borderRadius: theme.spacing(0.5),
        background: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: { xs: 'column', sm: 'row' },
        maxWidth: !context?.image?.image_url ? '550px' : '800px',
        [theme.breakpoints.between('sm', 'md')]: {
          maxWidth: '600px'
        },
        maxHeight: { xs: 'calc(90vh - 28px)' },
        minWidth: {
          xs: '100vw',
          sm: theme.spacing(53.75),
          md: theme.spacing(66.25)
        },
        overflow: 'auto',
        '& *': {
          fontFamily: theme.brandStyles?.body?.fontFamily
        },
        ...theme.brandStyles?.modal
      })}
      data-testid="container-box"
    >
      <Box
        sx={theme => ({
          display: {
            xs: 'flex',
            sm: 'none'
          },
          justifyContent: 'end',
          paddingX: theme.spacing(1.2),
          paddingY: theme.spacing(0.6)
        })}
      >
        <Close
          data-testid="small-close"
          onClick={() => {
            onClose();
          }}
          aria-hidden
          sx={theme => ({
            ...theme.brandStyles?.icon,
            cursor: 'pointer',
            '&:hover': {
              color: theme?.brandStyles?.icon?.hoverColor,
              opacity: theme?.brandStyles?.icon?.hoverOpacity
            }
          })}
        />
      </Box>
      {/* Left Box */}
      {context?.image?.image_url && (
        <Box
          sx={theme => ({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: { xs: 'hidden' },
            width: {
              xs: '100%',
              sm: '50%'
            },
            [theme.breakpoints.down('sm')]: {
              padding: theme.spacing(2)
            }
          })}
        >
          <Box
            component="img"
            src={context?.image?.image_url}
            alt="image"
            sx={{
              height: '100%',
              width: '100%',
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px',
              objectFit: 'cover',
              objectPosition: focalPointFloatsToString(
                context?.image?.focal_point_x,
                context?.image?.focal_point_y
              )
            }}
          />
        </Box>
      )}
      {/* Right Box */}
      <Box
        sx={theme => ({
          // Don't add with or height to this element, or we'll end up with white borders around the image.
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'between',
          overflow: 'auto',
          padding: {
            xs: theme.spacing(2),
            sm: `${theme.spacing(1.25)} ${theme.spacing(1.25)} ${theme.spacing(
              4
            )} ${theme.spacing(1.25)}`
          },
          width: {
            xs: '100%',
            sm: !context?.image?.image_url ? '100%' : '50%'
          }
        })}
        data-testid="right-box"
      >
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'flex'
            },
            justifyContent: 'end'
          }}
        >
          <Close
            data-testid="medium-close"
            onClick={() => {
              onClose();
            }}
            aria-hidden
            sx={theme => ({
              ...theme.brandStyles?.icon,
              cursor: 'pointer',
              '&:hover': {
                color: theme?.brandStyles?.icon?.hoverColor,
                opacity: theme?.brandStyles?.icon?.hoverOpacity
              }
            })}
          />
        </Box>
        <Box
          sx={theme => ({
            flex: 1,
            display: 'flex',
            padding: 2,
            justifyContent: 'center',
            flexDirection: 'column',
            height: 'content-fit',
            ...theme.brandStyles?.modal
          })}
        >
          <Typography
            variant="h3"
            align="center"
            sx={theme => ({
              ...theme.brandStyles?.header
            })}
          >
            {context.header}
          </Typography>
          <Typography
            align="center"
            sx={theme => ({
              mb: 2,
              ...theme.brandStyles?.body
            })}
          >
            {context.body}
          </Typography>
          {shouldDisplayAlertForm && (
            <AlertForm
              data-testid="alert-form"
              formContext={alert_form_context}
              handleEmailChange={handleEmailChange}
              handleBirthdayChange={handleBirthdayChange}
              handlePhoneNumberChange={handlePhoneNumberChange}
              errors={errors}
              formState={formState}
            />
          )}

          <AlertButton
            handleClick={onClick}
            type="submit"
            buttonProps={context.alertButton}
            isLoading={isLoading}
            customButtonLabel={customButtonLabel}
          />
          {shouldDisplayAlertForm && (
            <Button
              variant="contained"
              sx={theme => ({
                marginBottom: theme.spacing(2),
                borderRadius: 0,
                ...theme.brandStyles?.button,
                '&:hover': {
                  background:
                    theme?.brandStyles?.buttonHover?.hoverBackgroundColor,
                  borderColor:
                    theme?.brandStyles?.buttonHover?.hoverStrokeColor,
                  color: theme?.brandStyles?.buttonHover?.hoverFontColor
                }
              })}
              onClick={() => {
                onClose(NO_THANKS);
              }}
            >
              {NO_THANKS}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

ContentSection.propTypes = {
  context: PropTypes.shape({
    header: PropTypes.string,
    body: PropTypes.string,
    alertButton: PropTypes.shape({
      action: PropTypes.string
    }),
    image: PropTypes.shape({
      id: PropTypes.number,
      image_url: PropTypes.string,
      focal_point_x: PropTypes.number,
      focal_point_y: PropTypes.number
    })
  }).isRequired,
  alert_form_context: PropTypes.shape({
    success_body: PropTypes.string,
    success_header: PropTypes.string,
    alert_buttons: PropTypes.arrayOf(PropTypes.shape({})),
    optional_alert_form_fields_enabled: PropTypes.bool
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  handleBirthdayChange: PropTypes.func.isRequired,
  handlePhoneNumberChange: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  template: PropTypes.string,
  formState: PropTypes.string,
  isLoading: PropTypes.bool,
  customButtonLabel: PropTypes.string
};

ContentSection.defaultProps = {
  errors: [],
  template: '',
  formState: '',
  isLoading: false,
  customButtonLabel: null
};

export default ContentSection;
