import PropTypes from 'prop-types';
import React from 'react';

export const CONTEXT_DEFAULTS = {
  // Adding a flag? Step 1
  has_diner_signup_ff: false
};

export const FeatureFlagsContext = React.createContext(CONTEXT_DEFAULTS);

const FeatureFlagsProvider = ({ children, value }) => (
  <FeatureFlagsContext.Provider value={value}>
    {children}
  </FeatureFlagsContext.Provider>
);

FeatureFlagsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.shape({
    // Adding a flag? Step 2
    has_diner_signup_ff: PropTypes.bool
  })
};

FeatureFlagsProvider.defaultProps = {
  value: CONTEXT_DEFAULTS
};
export default FeatureFlagsProvider;
