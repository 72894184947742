import { get } from 'api/base';
import {
  buildApiPath,
  WEBSITE_STYLING_ENDPOINT,
  buildUrlWithHostname,
  getFormattedPathname,
  getHostname,
  getPreviewParam
} from 'api/helpers';
import { logError, logWarningToDataDog } from 'helpers/logging';
import alertTypes from './constants/alertTypes';

/**
 * Requests alert from API and resolves with data
 * @param {{
 *  alertType: keyof typeof import('./constants/alertTypes').default,
 *  has_diner_signup_ff: boolean
 * }} Options
 * @returns Data promise
 */
export const getAlert = async ({ alertType, has_diner_signup_ff = false }) => {
  const pathname = getFormattedPathname();
  const previewParam = getPreviewParam();
  const shouldUseV2Path =
    alertType === alertTypes.BANNER ? false : has_diner_signup_ff;
  const apiPath = buildApiPath(
    alertType,
    pathname,
    previewParam,
    shouldUseV2Path
  );
  const hostname = getHostname();
  const apiEndpointUrl = buildUrlWithHostname(hostname, apiPath);

  try {
    const response = await get(apiEndpointUrl);
    return response.data;
  } catch (error) {
    logWarningToDataDog(
      `Error requesting the active ${alertType} alert. Status: ${error?.response?.status}`,
      error
    );
    return null;
  }
};

/**
 * Requests Alert styles based on theme and customization
 * @returns Data promise
 *
 */
export const getStyles = async (bentoHostname = null) => {
  // getHostname relies on window.location.host
  // ex: for website https://ahmedtest.getbento.com/events/  the location.host is ahmedtest.getbento.com
  // However in the sushi previewer, the iframe of the previewer has a different location.host to the bento api.
  // ex: in prod it will be yourbento.com
  // So we pass in the proper one from sushi under the 'bentoHostname' and use it.
  const hostname = bentoHostname || getHostname();
  const apiEndpointUrl = buildUrlWithHostname(
    hostname,
    WEBSITE_STYLING_ENDPOINT
  );
  try {
    const response = await get(apiEndpointUrl);
    return response.data;
  } catch (error) {
    logError(
      `Error requesting the alert styles. Status: ${error?.response?.status}`,
      error
    );
    return null;
  }
};

export const getCSRFToken = async () => {
  const hostname = getHostname();
  const apiEndpointUrl = buildUrlWithHostname(hostname, '/csrf');

  try {
    const response = await get(apiEndpointUrl);
    return response.data.token;
  } catch (error) {
    logError(`Error requesting token: ${error?.response?.status}`, error);
    return null;
  }
};
