import React from 'react';
import {
  Grow,
  Modal,
  Slide,
  useMediaQuery,
  useTheme,
  Box
} from '@mui/material';

/**
 * Lightweight wrapper for MUI Modal that handles different transitions for mobile and desktop
 * @param {import('@mui/material').ModalProps} props
 */
const AlertModal = ({ open, onClose, children, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Modal
      slotProps={{
        backdrop: {
          style: {
            background: theme?.brandStyles?.modal?.overlayBackgroundColor
          }
        }
      }}
      open={open}
      onClose={onClose}
      keepMounted={false}
      {...props}
    >
      {isMobile ? (
        <Slide in={open} direction="up" data-testid="mobile-modal">
          <Box>{children}</Box>
        </Slide>
      ) : (
        <Grow in={open} data-testid="desktop-modal">
          <Box>{children}</Box>
        </Grow>
      )}
    </Modal>
  );
};

AlertModal.propTypes = Modal.propTypes;

export default AlertModal;
