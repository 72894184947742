/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React from 'react';
import Image from 'components/Image';

const OverlayAlertContent = ({ content, images }) => (
  <div
    id="popup-alert"
    className="popup popup--modal mfp-hide"
    data-bb-track-label="Alert"
    data-popup-auto-open="modal"
  >
    <div
      className="popup__content"
      dangerouslySetInnerHTML={{ __html: content }}
    />
    {images.length > 0 && (
      <div className="popup__media">
        {images.map(
          ({ id, image_url, alt_text, focal_point_x, focal_point_y }) => (
            <Image
              key={id}
              imageUrl={image_url}
              altText={alt_text}
              focalPointX={focal_point_x}
              focalPointY={focal_point_y}
            />
          )
        )}
      </div>
    )}
  </div>
);

OverlayAlertContent.propTypes = {
  content: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      image_url: PropTypes.string.isRequired,
      alt_text: PropTypes.string,
      focal_point_x: PropTypes.number,
      focal_point_y: PropTypes.number
    })
  )
};

OverlayAlertContent.defaultProps = {
  images: []
};

export default OverlayAlertContent;
