import { getHostname } from 'api/helpers';
import { environmentNames } from 'config/constants';

const getEnvironment = () => {
  // Determine what environment we're in by looking at the URL.
  let environment;
  let host;

  try {
    host = getHostname();
  } catch (e) {
    host = '';
  }

  if (!host) {
    host = '';
  }

  if (host === '') {
    environment = environmentNames.UNKNOWN;
  } else if (
    ['localtest.me', 'localhost'].some(substring => host.includes(substring))
  ) {
    environment = environmentNames.DEVELOPMENT;
  } else if (host.includes('bentosandbox.com')) {
    environment = environmentNames.STAGING;
  } else {
    // Production is more than just getbento.com since client sites can have their own domain.
    // Therefore, anything that doesn't fall under STAGING, DEVELOPMENT, or UNKNOWN is PRODUCTION.
    environment = environmentNames.PRODUCTION;
  }

  return environment;
};

export default getEnvironment;
