import React from 'react';
import PropTypes from 'prop-types';

const FujiImage = ({ image_url, alt_text, slidePosition, totalImages }) => (
  <li role="presentation">
    <div className="gallery-item">
      <div className="gallery-item__sizer" tabIndex="-1" />
      <div
        data-testid="gallery-item"
        className="gallery-item__img"
        style={{
          backgroundImage: `url(${image_url})`,
          backgroundPosition: 'none'
        }}
      >
        <img className="sr-only" alt={alt_text} />
      </div>
    </div>
    <span className="sr-only">
      Slide {slidePosition} of {totalImages}
    </span>
  </li>
);

FujiImage.propTypes = {
  image_url: PropTypes.string.isRequired,
  alt_text: PropTypes.string,
  slidePosition: PropTypes.number.isRequired,
  totalImages: PropTypes.number.isRequired
};

FujiImage.defaultProps = {
  alt_text: ''
};

export default FujiImage;
