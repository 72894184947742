import axios from 'axios';
import methods from 'api/constants/methods';
import { getBaseConfig } from 'api/helpers';

/**
 * Executes a GET request for the given path
 * @param {string} path
 * @returns Request promise
 */
export const get = path =>
  axios({
    ...getBaseConfig(path, methods.GET)
  });
