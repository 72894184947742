/**
 * Match start and end '/' in pathname (ie: /news/)
 */
const pathnameRegEx = /^\/|\/$/g;

/**
 * Replaces port in hostname with custom port for testing
 * @param {string} hostname
 * @param {number} port
 * @returns hostname with custom port
 */
const _replacePort = (hostname, port) =>
  hostname.replace(/:[0-9]{1,4}/, `:${port}`);

/**
 * Get the URL hostname
 * @returns hostname
 */
export const getHostname = () => {
  let hostname = window.location.host;
  const { REACT_APP_CUSTOM_API_PORT } = process.env;

  if (REACT_APP_CUSTOM_API_PORT && REACT_APP_CUSTOM_API_PORT !== 'false') {
    hostname = _replacePort(hostname, REACT_APP_CUSTOM_API_PORT);
  }

  return hostname;
};

/**
 * Styling options from website
 */
export const WEBSITE_STYLING_ENDPOINT = '/api/website/alerts_theme_options';

/**
 * Get the preview param from the current URL if it exists.
 * @returns query param
 */
export const getPreviewParam = () => {
  const previewSlug = new URLSearchParams(window.location.search).get(
    'entityPreview'
  );
  return previewSlug ? `&entityPreview=${previewSlug}` : '';
};

/**
 * Build path to get published alert from API
 * @param {string} alertType
 * @param {string} pathname
 * @param {string} previewParam
 * @param {boolean} v2
 * @returns path
 */
export const buildApiPath = (alertType, pathname, previewParam, v2 = false) =>
  `/api/alerts/${
    v2 ? 'v2/' : ''
  }${alertType}/published_website_entity_for_page?path=${pathname}${previewParam}`;

/**
 * Build url with hostname and path
 * @param {string} hostname
 * @param {string} path
 * @returns url
 */
export const buildUrlWithHostname = (hostname, path) =>
  `https://${hostname}${path}`;

/**
 * Get pathname without leading and trailing '/'s
 * @returns formatted pathname
 */
export const getFormattedPathname = () =>
  window.location.pathname.replace(pathnameRegEx, '') || '/';

/**
 * Get base config for an axios request
 * @param {string} path
 * @param {string} method
 * @returns base config
 */
export const getBaseConfig = (path, method) => ({
  headers: { 'Content-Type': 'application/json' },
  url: path,
  method
});

/**
 * Build path to get submit form
 * @param {string} alertId
 * @returns path
 */
export const getSubmitFormPath = alertId => {
  const apiPath = `/api/alerts/v2/overlay/${alertId}/submit_form`;
  const hostname = getHostname();
  const apiEndpointUrl = buildUrlWithHostname(hostname, apiPath);
  return apiEndpointUrl;
};

/**
 * Build path to get submit phone number
 * @param {string} alertId
 * @returns path
 */
export const getSubmitPhoneNumberPath = alertId => {
  const apiPath = `/api/alerts/v2/overlay/${alertId}/submit_phone_number`;
  const hostname = getHostname();
  const apiEndpointUrl = buildUrlWithHostname(hostname, apiPath);
  return apiEndpointUrl;
};
