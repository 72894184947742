/**
 * This is a polyfill for Object.entries
 * Source: https://stackoverflow.com/questions/45849831/object-entries-alternative-for-internet-explorer-and-reactjs
 *
 * Object.keys has more support than Object.entries
 * Source: https://caniuse.com/?search=object.keys
 * @param {any} object
 * @returns [key, value]
 */
export function entries(object) {
  if (!Object.entries) {
    const ownProps = Object.keys(object);
    let i = ownProps.length;
    const resArray = new Array(i);
    // eslint-disable-next-line no-plusplus
    while (i--) {
      resArray[i] = [ownProps[i], object[ownProps[i]]];
    }
    return resArray;
  }

  return Object.entries(object);
}
