import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from '@sentry/react';

import OverlayAlertRoot from 'containers/OverlayAlertRoot';
import BannerAlertRoot from 'containers/BannerAlertRoot';
import { initializeSentry, setMainHub } from 'logging/sentry';
import initializeDatadog from 'logging/datadog';
import { handleErrorBoundaryError, logError } from 'helpers/logging';
import {
  BANNER_HTML_ELEMENT_ID,
  OVERLAY_HTML_ELEMENT_ID
} from 'constants/alertHTMLIds';
import { initializeCloseOverlayEventListener } from 'helpers/closeOverlayEventHandler';
import GlobalStyle from 'helpers/GlobalStyle';
import FeatureFlagsProvider from 'providers/FeatureFlagsProvider';

initializeDatadog();
initializeSentry();
/*
 * Since the alerts-component is not builing the close button for overlays in Sensei/Osaka themes
 * and we don't have control of how the overlay can be closed:
 * (pressing escape button|clicking the close button|clicking outside of the overlay|etc),
 * the alerts-component is listening when the 'bentobox_close_overlay_event' event is triggered
 * to send this event to bento-analytics.
 * (Themes would trigger the 'bentobox_close_overlay_event' event once the overlay alert is closed).
 */
initializeCloseOverlayEventListener();

function createRootOverlayElement(elementTagToInsert = 'div') {
  const renderElement = document.createElement(elementTagToInsert);
  renderElement.id = OVERLAY_HTML_ELEMENT_ID;

  const lastBodyElement = document.body.lastChild;
  document.body.insertBefore(renderElement, lastBodyElement.nextSibling);

  return renderElement;
}

function createRootBannerElement(
  elementSelectorToInsert = '.site-notifications',
  elementTagToInsert = 'div'
) {
  const renderElement = document.createElement(elementTagToInsert);
  renderElement.id = BANNER_HTML_ELEMENT_ID;
  // Class for pushing down animation, look at the helpers/GlobalStyle.js for details
  renderElement.className = 'banner-max-height-animation';

  try {
    const elementToInsert = document.querySelector(elementSelectorToInsert);
    elementToInsert.prepend(renderElement);
  } catch (error) {
    logError('Error creating banner element', error);
    const firstBodyElement = document.body.firstChild;
    document.body.insertBefore(renderElement, firstBodyElement.nextSibling);
  }

  return renderElement;
}

const _initOverlayAlert = (elementTagToInsert, extraContext) => {
  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary
        beforeCapture={setMainHub}
        onError={handleErrorBoundaryError}
      >
        <FeatureFlagsProvider value={extraContext}>
          <OverlayAlertRoot />
        </FeatureFlagsProvider>
      </ErrorBoundary>
    </React.StrictMode>,
    createRootOverlayElement(elementTagToInsert)
  );
};

const _initBannerAlert = (elementSelectorToInsert, elementTagToInsert) => {
  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary
        beforeCapture={setMainHub}
        onError={handleErrorBoundaryError}
      >
        <GlobalStyle />
        <BannerAlertRoot />
      </ErrorBoundary>
    </React.StrictMode>,
    createRootBannerElement(elementSelectorToInsert, elementTagToInsert)
  );
};

const _overlayAlertComponent = { init: _initOverlayAlert };
const _bannerAlertComponent = { init: _initBannerAlert };

/* istanbul ignore next */
window.bentobox = Object.assign(window.bentobox || {}, {
  overlayAlertComponent: _overlayAlertComponent
});

/* istanbul ignore next */
window.bentobox = Object.assign(window.bentobox || {}, {
  bannerAlertComponent: _bannerAlertComponent
});
