export const EXTERNAL_LINK = 'external_link';
export const PAGE_LINK = 'link_to_page';
export const MERCH_LINK = 'merch_link';
export const SUBMIT_FORM = 'submit_form';
export const CLOSE_POPUP = 'close_popup';
export const NONE = 'no_button';

export const CADENCE_OPTIONS = {
  ONCE_PER_SITE_VISIT: 'once_per_site_visit',
  EVERY_TIME: 'every_time',
  IN_ONE_WEEK: 'in_one_week',
  IN_TWO_WEEKS: 'in_two_weeks',
  IN_THIRTY_DAYS: 'in_thirty_days',
  NEVER: 'never'
};

export const ONE_WEEK_IN_DAYS = 7;
export const TWO_WEEKS_IN_DAYS = 14;
export const THIRTY_DAYS = 30;

export const LOCAL_STORAGE = 'localStorage';
export const SESSION_STORAGE = 'sessionStorage';
export const ALERTS_STORAGE_ITEM = 'bento-alerts';

export const NO_THANKS = 'NO THANKS';

export const FORM_STATES = {
  INITIAL: 'INITIAL',
  SECONDARY: 'SECONDARY',
  SUCCESS: 'SUCCESS'
};

// Template types
export const PROMOTION = 'promotion';
export const ANNOUNCEMENT = 'announcement';
export const CUSTOM = 'custom';
export const FUJI = 'fuji';
