import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { dispatchWindowEvent } from 'helpers/eventDispatchHelper';
import trackEventWithBentoAnalytics from 'helpers/trackEventWithBentoAnalytics';
import { actionTypes, eventLabels, eventValues } from 'constants/tracking';
import { getAlert } from 'api';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { CUSTOM } from 'constants/dinerSignupConstants';

const BaseAlertRoot = ({
  templateComponentMap,
  templateCallbackMap,
  alertType,
  eventType
}) => {
  const [alert, setAlert] = useState();
  const { has_diner_signup_ff } = useFeatureFlags();

  const loadAlert = async () => {
    const data = await getAlert({ alertType, has_diner_signup_ff });
    setAlert(data);
  };

  useEffect(() => {
    loadAlert();
  }, []);

  useEffect(() => {
    if (alert?.template) {
      templateCallbackMap[alert.template.toUpperCase()](alert?.legacy_template);
      if (
        alert?.template.toLowerCase() === CUSTOM &&
        alert?.legacy_template &&
        alert?.legacy_template.toUpperCase() === 'FUJI'
      ) {
        // It is unclear exactly why we need to do this
        // but the best theory is that because in DinerSignupAlertsContainer we initially render null
        // before rendering the Fuji legacy component, the website (which applies JS and CSS in order to open and style the alert) tries to apply the css and js
        // on an empty node (or just doesn't)...
        // So having this timeout simply helps us send the message after the alert has been actually displayed
        setTimeout(() => {
          dispatchWindowEvent(eventType);
        }, 100);
      } else {
        dispatchWindowEvent(eventType);
      }
      trackEventWithBentoAnalytics(
        actionTypes.SHOW,
        `${eventLabels.ALERT_SHOWN}: ${eventValues.ALERT_TEMPLATE} - ${alert?.template}`
      );
    }
  }, [alert]);

  if (alert?.template) {
    const AlertContent = templateComponentMap[alert.template.toUpperCase()];
    return <AlertContent {...alert} />;
  }

  return null;
};

BaseAlertRoot.propTypes = {
  templateComponentMap: PropTypes.shape({
    SENSEI_OSAKA: PropTypes.func.isRequired,
    FUJI: PropTypes.func.isRequired
  }).isRequired,
  templateCallbackMap: PropTypes.shape({
    SENSEI_OSAKA: PropTypes.func.isRequired,
    FUJI: PropTypes.func.isRequired
  }).isRequired,
  alertType: PropTypes.string.isRequired,
  eventType: PropTypes.string.isRequired
};

export default BaseAlertRoot;
