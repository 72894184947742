import { datadogLogs } from '@datadog/browser-logs';
import {
  DATADOG_SITE,
  DATADOG_SERVICE,
  environmentNames
} from 'config/constants';
import getEnvironment from 'helpers/getEnvironment';

const initializeDatadog = () => {
  const { REACT_APP_VERSION } = process.env;

  const ENVIRONMENT_NAME = getEnvironment();

  if (ENVIRONMENT_NAME !== environmentNames.PRODUCTION) {
    return;
  }

  const { REACT_APP_DATADOG_CLIENT_TOKEN } = process.env;

  datadogLogs.init({
    clientToken: REACT_APP_DATADOG_CLIENT_TOKEN,
    site: DATADOG_SITE,
    service: DATADOG_SERVICE,
    env: ENVIRONMENT_NAME,
    version: REACT_APP_VERSION,
    forwardErrorsToLogs: false,
    sampleRate: 100
  });
};

export default initializeDatadog;
