import trackEventWithBentoAnalytics from 'helpers/trackEventWithBentoAnalytics';
import {
  actionTypes,
  eventLabels,
  CLOSE_OVERLAY_EVENT_NAME
} from 'constants/tracking';
import { OVERLAY_HTML_ELEMENT_ID } from 'constants/alertHTMLIds';

const closeOverlayEventHandler = event => {
  // Since the close event can be triggered by other overlays rather than the alert's v2 overlay
  // we need to check the html id of the element
  const eventDetailsId = event?.detail?.id || '';
  if (eventDetailsId === OVERLAY_HTML_ELEMENT_ID) {
    trackEventWithBentoAnalytics(
      actionTypes.CLICK,
      eventLabels.ALERT_CLOSE_BUTTON
    );
  }
};

export const initializeCloseOverlayEventListener = () =>
  window.addEventListener(CLOSE_OVERLAY_EVENT_NAME, closeOverlayEventHandler);
