import { datadogLogs } from '@datadog/browser-logs';
import {
  logExceptionToSentry,
  logToSentry,
  resetMainHub
} from 'logging/sentry';

/**
 * This helper will log an error to sentry & datadog with given message.
 *
 * Optionally, if Error object is provided,
 * the message and stack trace will be logged
 *
 * @param message {string}  error message
 * @param error   {Error}   optional error to be logged
 */
export const logError = (message, e = null) => {
  if (e !== null) {
    logExceptionToSentry(e, message);
  } else {
    logToSentry(message, 'error');
  }
  const errorContext = e
    ? { errorMessage: e?.message, errorStack: e?.stack }
    : {};
  datadogLogs.logger.error(message, errorContext);
};

/**
 * This helper will log a warning to sentry & datadog with given message.
 *
 * @param message {string} warning message
 */
export const logWarning = message => {
  logToSentry(message, 'warning');
  datadogLogs.logger.warn(message);
};

export const logWarningToDataDog = message => {
  datadogLogs.logger.warn(message);
};

/**
 * This helper will log an info message to sentry & datadog.
 *
 * @param message {string} warning message
 */
export const logInfo = message => {
  logToSentry(message, 'info');
  datadogLogs.logger.info(message);
};

/**
 * This helper will reset the Sentry Hub to the main hub and
 * log an error message to datadog.
 *
 * @param e               {Error}   error object to be logged
 * @param componentStack  {string}  component stack to be logged
 */
export const handleErrorBoundaryError = (e, componentStack) => {
  resetMainHub();
  datadogLogs.logger.error(e?.message, { componentStack });
};
