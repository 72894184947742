import PropTypes from 'prop-types';
import React from 'react';
import { TextField } from '@mui/material';

const StyledSelect = ({
  onChange,
  label,
  value,
  children,
  dataTestId,
  disabled
}) => (
  <TextField
    select
    shrink={false}
    notched={false}
    size="small"
    sx={theme => ({
      borderRadius: '4px',
      backgroundColor: theme.brandStyles?.form?.backgroundColor,
      fontFamily: theme.brandStyles?.form?.fontFamily,
      fieldset: {
        border: theme.brandStyles?.form?.border
      }
    })}
    InputLabelProps={{
      shrink: false,
      sx: theme => ({
        textTransform: theme.brandStyles?.form?.textTransform,
        color: theme.brandStyles?.form?.color
      })
    }}
    InputProps={{
      shrink: false,
      sx: theme => ({
        textTransform: theme.brandStyles?.form?.textTransform,
        color: theme.brandStyles?.form?.color
      })
    }}
    data-testid={dataTestId}
    value={value}
    label={label}
    onChange={onChange}
    disabled={disabled}
  >
    {children}
  </TextField>
);

StyledSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.string
};
StyledSelect.defaultProps = {
  children: {},
  disabled: false
};

export default StyledSelect;
