/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React from 'react';
import BirthdayField from 'components/BirthdayField';
import PhoneNumberField from 'components/PhoneNumberField';
import { EmailField } from 'components/EmailField';
import { FORM_STATES } from 'constants/dinerSignupConstants';

const AlertForm = ({
  formContext,
  handleEmailChange,
  handleBirthdayChange,
  handlePhoneNumberChange,
  errors,
  formState
}) =>
  formContext.alert_form_fields.map(field => {
    if (!field.enabled) {
      return null;
    }

    switch (field.input_type) {
      case 'email':
        if (formState === FORM_STATES.INITIAL) {
          return (
            <EmailField
              key={field.id ?? field.input_type}
              handleEmailChange={handleEmailChange}
              field={field}
              error={errors?.email}
            />
          );
        }
        return null;
      case 'birthday':
        if (formState === FORM_STATES.INITIAL) {
          return (
            <BirthdayField
              key={field.id ?? field.input_type}
              error={errors?.birthday}
              onChange={handleBirthdayChange}
            />
          );
        }
        return null;
      case 'phone_number':
        if (formState === FORM_STATES.SECONDARY) {
          return (
            <PhoneNumberField
              key={field.id ?? field.input_type}
              onChange={handlePhoneNumberChange}
              field={field}
              error={errors?.phone_number}
            />
          );
        }
        return null;
      default:
        // Unlikely we will get here
        // Ignore if doesnt match any above
        return null;
    }
  });

AlertForm.propTypes = {
  formContext: PropTypes.shape({
    alert_form_fields: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string
      })
    )
  }).isRequired,
  handleEmailChange: PropTypes.func,
  handleBirthdayChange: PropTypes.func,
  handlePhoneNumberChange: PropTypes.func,
  formState: PropTypes.string
};

export default AlertForm;
