import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  .banner-max-height-animation {
    transition: max-height 1.5s ease 0.5s;
    max-height: 0;

    /*
      This max-height is harcoded to 300px to avoid cutting any part of the banner
      if you want to modify it, please modify animation time on banner-max-height-animation class as well
    */  
    &.banner-open {
      max-height: 300px;
    }
  }
`;

export default GlobalStyle;
