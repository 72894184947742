/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React from 'react';

import trackEventWithBentoAnalytics from 'helpers/trackEventWithBentoAnalytics';
import { actionTypes, eventLabels } from 'constants/tracking';

const BannerAlertContent = ({ content }) => (
  <div className="alert__container">
    <div
      className="wysiwyg alert__content"
      dangerouslySetInnerHTML={{ __html: content }}
    />
    <button
      type="button"
      className="alert__close-btn"
      aria-label="Close"
      onClick={() =>
        trackEventWithBentoAnalytics(
          actionTypes.CLICK,
          eventLabels.ALERT_CLOSE_BUTTON
        )
      }
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
);

BannerAlertContent.propTypes = {
  content: PropTypes.string.isRequired
};

export default BannerAlertContent;
