import React from 'react';
import PropTypes from 'prop-types';

import { FormControl, TextField, Box } from '@mui/material';

export const EmailField = ({ handleEmailChange, field, error }) => (
  <Box key={field.label} marginBottom="1rem">
    <FormControl fullWidth>
      <TextField
        sx={theme => ({
          borderRadius: '4px',
          backgroundColor: theme.brandStyles?.form?.backgroundColor,
          fieldset: {
            border: theme.brandStyles?.form?.border
          },
          input: {
            color: theme.brandStyles?.form?.color,
            textTransform: theme.brandStyles?.form?.textTransform
          }
        })}
        placeholder={field.label}
        name={field.label}
        aria-describedby={field.label}
        inputProps={{ style: { height: '.5rem' } }}
        onChange={handleEmailChange}
        helperText={error}
        error={!!error}
      />
    </FormControl>
  </Box>
);

EmailField.propTypes = {
  field: PropTypes.shape().isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  error: PropTypes.objectOf()
};
EmailField.defaultProps = {
  error: undefined
};
