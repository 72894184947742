import PropTypes from 'prop-types';
import React from 'react';

import { getFocalPointStyle } from 'helpers/focalPointHelper';

const Image = ({ imageUrl, altText, focalPointX, focalPointY }) => (
  <img
    src={imageUrl}
    loading="eager"
    alt={altText}
    style={{ objectPosition: getFocalPointStyle(focalPointX, focalPointY) }}
  />
);

Image.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  altText: PropTypes.string,
  focalPointX: PropTypes.number,
  focalPointY: PropTypes.number
};

Image.defaultProps = {
  altText: 'Alert Image',
  focalPointX: null,
  focalPointY: null
};

export default Image;
