import React from 'react';

import BaseAlertRoot from 'containers/BaseAlertRoot';
import eventTypes from 'constants/eventTypes';
import { OVERLAY_TEMPLATE_COMPONENT_MAP } from 'templateComponentMap';
import { OVERLAY_ROOT_INIT_FUNCTION_MAP } from 'rootInitFunctionMap';
import alertTypes from 'api/constants/alertTypes';

const OverlayAlertRoot = () => (
  <BaseAlertRoot
    templateComponentMap={OVERLAY_TEMPLATE_COMPONENT_MAP}
    templateCallbackMap={OVERLAY_ROOT_INIT_FUNCTION_MAP}
    alertType={alertTypes.OVERLAY}
    eventType={eventTypes.SHOW_OVERLAY}
  />
);

export default OverlayAlertRoot;
